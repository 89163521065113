const [ w, mq ] = [window, window.matchMedia("(max-width:900px)") ];

const domReady = () => {
  if(mq.matches){
    $(w).on('scroll', function(){
      const scroll = $(this).scrollTop();
      if(scroll > 200){
        $('header').addClass('hide');
      } else {
        $('header').removeClass('hide');
      }
    });
  }
};

export default function SHOP () {
  $(w).on('load', domReady);
}