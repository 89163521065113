import TAGSORT,{taglist} from "../class/tagsort";

const [ w, d, mq ] = [window, document, window.matchMedia("(min-width:900px)") ];

const itemTemplate =  `
  <ul class="stock-list" :class="[ 'is-paged-' + paged ]">
    <li v-for="(article , ind) in pagedArticles">
      <a :href="article.href">
        <figure>
          <img :src="article.thumb" :alt="article.title">
          <figcaption class="soldout" v-if="article.keywords.includes('SOLD OUT')">SOLD OUT</figcaption>
        </figure>
        <div class="text_wrap">
          <p class="cate" v-if="article.category.name">{{ article.category.name }}</p>
          <p class="ttl">{{ article.title }}</p>
          <p class="price">{{ article.desc }}</p>
          <ul class="tag_wrap" v-if="article.keywords.length">
            <li v-for="(tag, ind) in article.keywords">#{{ tag }}</li>
          </ul>
        </div>
      </a>
    </li>
  </ul>
  `;

const relatedItemTemplate =  `
  <div class="connection-list is-related" v-if="relatedArticles.length">
    <h3 class="connection-list__title">関連実績</h3>
    <ul class="connection-list__inner stock-list">
      <li v-for="(article , ind) in relatedArticles">
        <a :href="article.href">
          <figure>
            <img :src="article.thumb" :alt="article.title">
          </figure>
          <div class="text_wrap">
            <p class="cate" v-if="article.category.name">{{ article.category.name }}</p>
            <p class="ttl">{{ article.title }}</p>
            <p class="price">{{ article.desc }}</p>
            <ul class="tag_wrap" v-if="article.keywords.length">
              <li v-for="(tag, ind) in article.keywords">#{{ tag }}</li>
            </ul>
        </div>
        </a>
      </li>
    </ul>
  </div>
  `;

const guideMenu = `
  <div class="guide_wrap">
    <p class="menu_ttl open">ご利用ガイド</p>
    <ul class="guide_list list">
      <!--<li><a href="">ご購入の流れ</a></li>-->
      <!--<li><a href="">返品について</a></li>-->
      <li><a href="https://swag-baller.shop-pro.jp/?mode=sk" target="_blank" rel="noopener noreferrer">特定商取引法に基づく表記</a></li>
    </ul>
    <ul class="btn_list">
      <!--<li><a class="more" href="">詳細に調べたい方はこちら</a></li>-->
      <li><a class="line" href="https://lin.ee/XGae66c" target="_blank" rel="noopener noreferrer"><img src="/assets/img/common/icon_line.svg">商品のお問い合わせ</a></li>
    </ul>
  </div>
`;

const listTtl = `
  <div class="ttl_wrap">
    <h3>ALL</h3>
    <p class="sales"><a href="/stock/?mode=list&tag=販売中">販売中の一覧を表示</a></p>
  </div>
`;

class taglist_custom extends taglist {
  constructor( opt, parent ) {
    super( opt, parent );
  }
  insertContainer ( tag = `<taglist />`) {
    const container = d.querySelector( this.opt.container || '.rwd002-container');
    // const pagination = d.querySelector( this.opt.pagination || '.rwd002-pagination');
    // const is_list = d.body.classList.contains('is--list');
    if( !container ) return false;

    // top
    container.insertAdjacentHTML('beforebegin', tag);
    // if( is_list ){
    // }

    // bottom
    // if( pagination ){
    //   pagination.insertAdjacentHTML('afterend', tag);
    // }else {
    //   container.insertAdjacentHTML('afterend', tag);
    // }
  }
}

class OPTIONS {
  constructor(query,categories) {
    this.ttlAdd(query,categories);
    this.guideAdd();
    this.bottomTtlClone();
    this.navSlide();
  }

  // 一覧カテ見出し追加
  ttlAdd(query, categories) {
    const is_list = d.body.classList.contains('is--list');
    const $listTtl = $(listTtl);

    if( is_list ){
      // カテゴリータイトル挿入
      $listTtl.prependTo('.rwd002-main');
      
      const catObj = categories.find( cat => cat.id == query.cat );
      let cateName = catObj ? catObj.name : 'ALL';
      let tagName = query.tag || false;

      $('h3', $listTtl).text( cateName );
      if( tagName ) $('h3', $listTtl).text( '#' + tagName );
    }
  }

  // ご利用ガイド追加
  guideAdd() {
    if(mq.matches){
      $(guideMenu).appendTo('.rwd002-taglist');
    } else {
      $(guideMenu).insertAfter('.rwd002-pagination');
    }
  }

  // 詳細タイトル売約ボタン上に複製
  bottomTtlClone() {
    const is_detail = d.body.classList.contains('is--detail');
    if( is_detail ){
      $('.rwd002-detail__head').clone().addClass('bottom_ttl').insertBefore('.last_btn');
    }
  }

  // SP時のサイドナビのアコーディオン
  navSlide() {
    if(!mq.matches){
      $('.menu_ttl').on('click', function(){
        $(this).toggleClass('open');
        $(this).next('.list').slideToggle();
      });
    }
  }
}

const domReady = () => {
  
  const Tagsort = new TAGSORT({
    list: {
      itemTemplate,
      perpage: 18, // 一度に表示する記事数
    },
    taglist: {
      pagination: '.rwd002-pagination', // taglistを表示する基準の要素
    },
    related: {
      container: '.rwd002-pagination',
      itemTemplate: relatedItemTemplate
    }
  });

  d.addEventListener('tagsort.beforeInit', e => {
    const TAGSORT = e.detail;
    Tagsort.taglist = new taglist_custom( TAGSORT.opt.taglist, TAGSORT );
  });

  d.addEventListener('tagsort.afterRender', e => {
    const searchParams = new URLSearchParams( location.search );
    let queryEntries = searchParams.entries();
    let queryParamsObject = Object.fromEntries(queryEntries);
    new OPTIONS(queryParamsObject, e.detail.categories);
  });

  // Tagsort.init.then( tagsort => {  })

  Tagsort.render();
};

export default function STOCK () {
  // テンプレートが用意したDOMContentLoaded（テンプレートインスタンスが引数に入る）
  d.addEventListener('rwd002.beforeDomready', e => domReady(e.detail) );
}