import Swiper from "swiper/bundle";
import GET_ARTICLES from "../class/getArticles";

const [ w, d, mq ] = [window, document, window.matchMedia("(min-width:900px)") ];
const domReady = TPL => {
  new BLOCKS;
};

class BLOCKS {
  constructor() {
    this.opening();
    this.mainvisual();
    this.blog();
    this.stock();
  }

  opening() {
    let Mv = document.querySelector('.mainvisual');
    let Op = document.querySelector('#opening');
    w.addEventListener('load', function() {
      Mv.classList.add('loaded');
      Op.classList.add('loaded');
    });
  }

  mainvisual() {
    //メインスライダー
    const mvSlide = new Swiper('.mv_slide[data-plugin="mainSlider"]',{
      loop: true,
      effect: 'fade',
      speed: 2000,
      fadeEffect: {
        crossFade: true, // クロスフェードを有効にする（フェードモードの場合 true 推奨）
      },
      autoplay:{
        delay: 6000,
        disableOnInteraction: false,
        waitForTransition: false
      },
    });

    $(w).on('scroll', function(){
      const scroll = $(this).scrollTop();
      $('.scrollLeaf').css('transform', 'translateY(' + scroll * .3 + 'px');
      $('.scrollLeaf2').css('transform', 'translateY(' + scroll * .1 + 'px');
    });
  }

  blog() {
    const getArticle = new GET_ARTICLES('.block-blog .blog_list');
    getArticle.makeItem = (item,content) => `
    <li>
      <a${(item.is_new)? ' class="is-new"': ''} href="${item.href}">
        <figure><img src="${item.thumb}" alt="${item.title}"></figure>
        <div class="text_wrap">
          <p class="info"><span class="date">${item.date}</span><span class="cate">${item.category.name}</span></p>
          <p class="ttl">${item.title}</p>
        </div>
      </a>
    </li>`;
    // getArticle.makeItem = (item) => console.log(item);
    getArticle.render();
  }

  stock() {
    const getArticle = new GET_ARTICLES('.block-stock .stock_list');
    getArticle.makeItem = (item,content) => `
    <li>
      <a${(item.is_new)? ' class="is-new"': ''} href="${item.href}">
        <figure ${( item.keywords != null && item.keywords.includes('SOLD OUT'))? `class="soldout"`:''}><img src="${item.thumb}" alt="${item.title}"></figure>
        <div class="text_wrap">
          <p class="cate">${item.category.name}</p>
          <p class="ttl">${item.title}</p>
          <p class="price">${item.desc}</p>
        </div>
      </a>
    </li>`;
    // getArticle.makeItem = (item) => console.log(item);
    getArticle.render();
  }
  
  fetchCategories (content) {
    return fetch(`/assets/api/getCategories/?content=${content}`)
      .then(r => r.json())
      .catch(e => { throw new Error(e)});
  }

  fetchArticles (opt){
    return fetch(`/assets/api/getArticles/?${ new URLSearchParams(opt)}`)
    .then(r => r.json())
    .catch(e => { throw new Error(e)});
  }
}

const jqInit = () => false;

export default function HOME () {
  // テンプレートが用意したDOMContentLoaded（テンプレートインスタンスが引数に入る）
  d.addEventListener('rwd002.beforeDomready', e => domReady(e.detail) );

  $(() => jqInit());
}